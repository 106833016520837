<script setup lang="ts" generic="T">
import type { UISliderBasicProps } from './UISliderBasic.props'

import type { EmblaOptionsType } from 'embla-carousel'
import emblaCarouselVue from 'embla-carousel-vue'

const props = withDefaults(defineProps<UISliderBasicProps<T>>(), {
  grapCursor: false,
  pagination: false,
  arrows: false,
  navigationColor: 'black',
})

const options: EmblaOptionsType = {
  startIndex: props.selectedItem ?? 0,
  ...(props.breakpoints && {
    breakpoints: props.breakpoints,
  }),
}

const [emblaRef, emblaApi] = emblaCarouselVue(options)

/* Navigation Dots */
const navigationDots = ref<Array<HTMLElement>>([])

const createNavigation = () => {
  navigationDots.value = []
  if (props.navigationElementId) {
    const navigationContainer = document.getElementById(
      props.navigationElementId
    )
    if (navigationContainer && emblaApi.value) {
      navigationContainer.innerHTML = emblaApi.value
        .scrollSnapList()
        .map(
          () =>
            `<button class="embla__dot embla__dot--${props.navigationColor}" type="button"></button>`
        )
        .join('')

      navigationDots.value = Array.from(
        navigationContainer.querySelectorAll('.embla__dot')
      )

      navigationDots.value.forEach((dotNode: HTMLElement, index: number) => {
        dotNode.addEventListener(
          'click',
          () => {
            if (emblaApi.value) emblaApi.value.scrollTo(index)
          },
          false
        )
      })
    }
  }
}

const destroyNavigation = () => {
  if (props.navigationElementId) {
    const navigationContainer = document.getElementById(
      props.navigationElementId
    )
    if (navigationContainer) {
      navigationContainer.innerHTML = ''
    }
  }
}

const toggleNavigationStatus = () => {
  if (emblaApi.value) {
    const previous = emblaApi.value.previousScrollSnap()
    const selected = emblaApi.value.selectedScrollSnap()
    navigationDots.value[previous].classList.remove('embla__dot--selected')
    navigationDots.value[selected].classList.add('embla__dot--selected')
  }
}

/* Arrows */
const prevBtnDisabled = ref(false)
const nextBtnDisabled = ref(false)

const prevButtonClickHandler = () => {
  if (emblaApi.value && !prevBtnDisabled.value) {
    emblaApi.value.scrollPrev()
  }
}

const nextButtonClickHandler = () => {
  if (emblaApi.value && !nextBtnDisabled.value) {
    emblaApi.value.scrollNext()
  }
}

const onSelect = () => {
  if (emblaApi.value) {
    prevBtnDisabled.value = !emblaApi.value.canScrollPrev()
    nextBtnDisabled.value = !emblaApi.value.canScrollNext()
  }
}

onMounted(() => {
  if (emblaApi.value) {
    if (props.navigationElementId) {
      emblaApi.value
        .on('init', createNavigation)
        .on('reInit', createNavigation)
        .on('init', toggleNavigationStatus)
        .on('reInit', toggleNavigationStatus)
        .on('select', toggleNavigationStatus)
    }

    if (props.arrows) {
      emblaApi.value
        .on('init', onSelect)
        .on('reInit', onSelect)
        .on('select', onSelect)
    }
  }
})

onUnmounted(() => {
  destroyNavigation()
})
</script>
<template>
  <div ref="emblaRef" class="embla relative overflow-hidden">
    <div class="embla__container flex" :class="{ 'cursor-grab': grapCursor }">
      <slot
        v-for="(item, index) in items"
        :key="`${id}__item__${index}`"
        name="slide"
        class="embla__slide"
        v-bind="{ item, index }"
      />
    </div>
    <template v-if="arrows">
      <button
        class="embla__arrow embla__arrow--prev"
        :class="{
          'embla__arrow--disabled': prevBtnDisabled,
        }"
        aria-label="Prev slide"
        role="button"
        :aria-disabled="prevBtnDisabled"
        :tabindex="prevBtnDisabled ? -1 : 0"
        @click="prevButtonClickHandler"
      >
        <DefaultIconsChevronLeft aria-hidden="true" />
      </button>
      <button
        class="embla__arrow embla__arrow--next"
        :class="{
          'embla__arrow--disabled': nextBtnDisabled,
        }"
        aria-label="Next slide"
        role="button"
        :aria-disabled="nextBtnDisabled"
        :tabindex="nextBtnDisabled ? -1 : 0"
        @click="nextButtonClickHandler"
      >
        <DefaultIconsChevronRight aria-hidden="true" />
      </button>
    </template>
  </div>
</template>

<style lang="scss">
.embla {
  &__dot {
    @apply bg-primitives-grey-150;

    width: 0.5rem;
    height: 0.5rem;
    display: inline-block;
    border-radius: 50%;
    margin: 0 4px;
    opacity: 0.2;

    &--selected {
      @apply bg-primitives-black;
      opacity: 1;

      &.embla__dot--white {
        @apply bg-primitives-white;
      }
    }
  }

  &__arrow {
    position: absolute;
    top: calc((100% - 48px) / 2 + 12px); //TODO: copied from swiper, to check
    margin-top: -22px; //TODO: copied from swiper, to check
    width: 50px;
    height: 50px;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;

    &--prev {
      left: 10px;
    }

    &--next {
      right: 10px;
    }

    &--disabled {
      opacity: 0.35;
      cursor: auto;
      pointer-events: none;
    }
  }
}
</style>
